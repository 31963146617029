<template>
  <div>
    <div class="d-sm-block d-mb-block d-lg-none" style="overflow-x: hidden">
      <Header2></Header2>
      <div class="distributor-container-mobile">
        <div class="first-block-mobile">
          <h2 class="title-name-mobile">{{ $t("distributors") }}</h2>
          <img
            src="../assets/img/dist-1.svg"
            class="distributor-1-mobile"
            alt="distributor-picture"
          />
          <p class="description-text-mobile">
            {{ $t("distributors-text-1") }}
          </p>
          <p class="description-text-mobile mt-5">
            {{ $t("distributors-text-2") }}
          </p>
          <p class="description-text-bold-mobile mt-4">
            {{ $t("distributors-text-3") }}
          </p>

          <div class="btn-container-mobile">
            <button
              @click="openModalCoopMobile"
              class="btn-mobile btn-mobile-1"
            >
              {{ $t("Application_for_cooperation") }}
            </button>
            <button @click="openModalDownloadMobile" class="btn-mobile">
              {{ $t("catalog-btn") }}
            </button>
          </div>
        </div>
        <div class="second-block-mobile">
          <div class="img-container-mobile">
            <img
              src="../assets/img/dist-2.png"
              class="distributor-picture-big-mobile img-hidden"
              alt="distributor-picture-big"
              loading="lazy"
            />
          </div>
          <div class="img-container-2-mobile">
            <img
              src="../assets/img/dist-3.png"
              class="distributor-picture-big-mobile"
              alt="distributor-picture-big"
              loading="lazy"
            />
          </div>
        </div>
        <v-dialog
          class="v-dialog-border"
          v-model="showDialogCoopMobile"
          max-width="537"
        >
          <v-card>
            <v-card-title class="application-title"
              >{{ $t("application-title") }}
              <v-spacer></v-spacer>
              <v-btn icon @click="closeDialogCoopMobile">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text>
              <div class="name">
                <label>{{ $t("input-company-name") }}</label>
                <input
                  v-model="name"
                  ref="nameTextarea"
                  type="text"
                  :placeholder="textareaPlaceholder"
                  class="name-input"
                />
              </div>
              <div class="name">
                <label>{{ $t("input-FIO") }}</label>
                <span class="red-dot">*</span>
                <input
                  v-model="fullname"
                  ref="fullnameTextarea"
                  type="text"
                  placeholder=""
                  class="name-input"
                />
              </div>
              <div class="name">
                <label>{{ $t("callback-label-3") }}</label>
                <input
                  v-model="email"
                  ref="emailTextarea"
                  type="text"
                  placeholder=""
                  class="name-input"
                />
              </div>
              <div class="name" style="padding-bottom: 14px">
                <label>{{ $t("input-phone") }}</label>
                <span class="red-dot">*</span>
                <input
                  v-model="phoneNum"
                  ref="phoneNumTextarea"
                  v-mask="'+7 (###) ###-##-##'"
                  type="tel"
                  placeholder=""
                  class="name-input py-3"
                />
              </div>
              <div class="name">
                <label>{{ $t("input-message") }}</label>
                <textarea
                  v-model="message"
                  ref="messageTextarea"
                  type="text"
                  placeholder=""
                  class="name-input-message"
                ></textarea>
              </div>
              <div class="text-warning d-flex">
                <label class="checkbox-container">
                  <input
                    type="checkbox"
                    id="consentCheckbox"
                    class="custom-checkbox"
                    v-model="consentChecked"
                  />
                  <span class="checkmark">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                    >
                      <path
                        d="M11.6673 3.5L5.25065 9.91667L2.33398 7"
                        stroke="white"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </span>
                </label>
                <p class="">
                  {{ $t("input-i-agree") }}
                  <router-link class="policy-text-mobile" to="/privacy">{{
                    $t("input-i-agree-2")
                  }}</router-link>
                </p>
              </div>
              <button
                v-on:click="handleSubmit"
                class="btn-call-modal"
                :disabled="!isValidAgree"
              >
                {{ $t("input-send-aply") }}
              </button>
            </v-card-text>
          </v-card>
        </v-dialog>
        <v-dialog
          class="v-dialog-border"
          v-model="downloadDialogMobile"
          max-width="537"
        >
          <v-card>
            <v-card-title class="application-title"
              >{{ $t("download-catalog-title") }}
              <v-spacer></v-spacer>
              <v-btn icon @click="closeDialogDownLoadMobile">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text>
              <p class="catalog-text-download">
                {{ $t("download-catalog-text") }}
              </p>
            </v-card-text>
            <v-card-text>
              <div class="name">
                <label>{{ $t("name2") }}</label>
                <input
                  v-model="name"
                  ref="nameTextarea"
                  type="text"
                  :placeholder="textareaPlaceholder"
                  class="name-input"
                />
              </div>

              <div class="name">
                <label
                  >{{ $t("email2") }}<span class="red-dot pl-2">*</span></label
                >
                <input
                  v-model="email"
                  ref="emailTextarea"
                  type="text"
                  placeholder=""
                  class="name-input"
                />
              </div>

              <div class="text-warning d-flex">
                <label class="checkbox-container">
                  <input
                    type="checkbox"
                    id="consentCheckbox"
                    class="custom-checkbox"
                    v-model="consentChecked"
                  />
                  <span class="checkmark">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                    >
                      <path
                        d="M11.6673 3.5L5.25065 9.91667L2.33398 7"
                        stroke="white"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </span>
                </label>
                <p class="">
                  {{ $t("input-i-agree") }}
                  <router-link class="policy-text-mobile" to="/privacy">{{
                    $t("input-i-agree-2")
                  }}</router-link>
                </p>
              </div>
              <button
                v-on:click="handleSubmitCatalog"
                class="btn-call-modal"
                :disabled="!isValidCatalog"
              >
                {{ $t("input-send-aply") }}
              </button>
            </v-card-text>
          </v-card>
        </v-dialog>
        <v-snackbar v-model="snackbar" :timeout="5000" class="btn-contaiener">
          {{ this.messageSnack }}
          <template v-slot:action="{ attrs }">
            <div
              class="snackbar-btn"
              text
              v-bind="attrs"
              @click="snackbar = false"
            >
              Закрыть
            </div>
          </template>
        </v-snackbar>
      </div>
    </div>
    <div class="d-none d-lg-block d-xl-block d-xxl-block">
      <Header2></Header2>
      <div class="distributor-container">
        <div class="first-block">
          <h2 class="title-name">{{ $t("distributors") }}</h2>
          <img
            src="../assets/img/dist-1.svg"
            class="distributor-1"
            alt="distributor-picture"
          />
          <p class="description-text">
            {{ $t("distributors-text-1") }}
          </p>
          <p class="description-text mt-5">
            {{ $t("distributors-text-2") }}
          </p>
          <p class="description-text-bold">
            {{ $t("distributors-text-3") }}
          </p>

          <div class="btn-container">
            <button @click="openModal" class="btn-distributor">
              {{ $t("Application_for_cooperation") }}
            </button>
            <button @click="openModalDownload" class="btn-download">
              {{ $t("catalog-btn") }}
            </button>
          </div>
        </div>
        <div class="second-block">
          <div class="img-container">
            <img
              src="../assets/img/dist-2.png"
              class="distributor-picture-big-1"
              alt="distributor-picture-big"
              loading="lazy"
            />
          </div>
          <div class="img-container-2">
            <img
              src="../assets/img/dist-3.png"
              class="distributor-picture-big"
              alt="distributor-picture-big"
              loading="lazy"
            />
          </div>
        </div>
        <v-dialog class="v-dialog-border" v-model="showDialog" max-width="537">
          <v-card>
            <v-card-title class="application-title"
              >{{ $t("application-title") }}
              <v-spacer></v-spacer>
              <v-btn icon @click="closeDialog">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text>
              <div class="name">
                <label>{{ $t("input-company-name") }}</label>
                <input
                  v-model="name"
                  ref="nameTextarea"
                  type="text"
                  :placeholder="textareaPlaceholder"
                  class="name-input"
                />
              </div>
              <div class="name">
                <label>{{ $t("input-FIO") }}</label>
                <span class="red-dot pl-1">*</span>
                <input
                  v-model="fullname"
                  ref="fullnameTextarea"
                  type="text"
                  placeholder=""
                  class="name-input"
                />
              </div>
              <div class="name">
                <label>{{ $t("callback-label-3") }}</label>
                <input
                  v-model="email"
                  ref="emailTextarea"
                  type="text"
                  placeholder=""
                  class="name-input"
                />
              </div>
              <div class="name" style="padding-bottom: 14px">
                <label>{{ $t("input-phone") }}</label>
                <span class="red-dot pl-1">*</span>
                <input
                  v-model="phoneNum"
                  ref="phoneNumTextarea"
                  v-mask="'+7 (###) ###-##-##'"
                  type="tel"
                  placeholder=""
                  class="name-input py-3"
                />
              </div>
              <div class="name">
                <label>{{ $t("input-message") }}</label>
                <textarea
                  v-model="message"
                  ref="messageTextarea"
                  type="text"
                  placeholder=""
                  class="name-input-message"
                ></textarea>
              </div>
              <div class="text-warning d-flex mt-4">
                <label class="checkbox-container">
                  <input
                    type="checkbox"
                    id="consentCheckbox"
                    class="custom-checkbox"
                    v-model="consentChecked"
                  />
                  <span class="checkmark">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                    >
                      <path
                        d="M11.6673 3.5L5.25065 9.91667L2.33398 7"
                        stroke="white"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </span>
                </label>
                <p class="">
                  {{ $t("input-i-agree") }}
                  <router-link class="policy-text-mobile" to="/privacy">{{
                    $t("input-i-agree-2")
                  }}</router-link>
                </p>
              </div>
              <button
                v-on:click="handleSubmit"
                class="btn-call-modal"
                :disabled="!isValidAgree"
              >
                {{ $t("input-send-aply") }}
              </button>
            </v-card-text>
          </v-card>
        </v-dialog>
        <v-dialog
          class="v-dialog-border"
          v-model="downloadDialog"
          max-width="537"
        >
          <v-card>
            <v-card-title class="application-title"
              >{{ $t("download-catalog-title") }}
              <v-spacer></v-spacer>
              <v-btn icon @click="closeDialogDownLoad">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text>
              <p class="catalog-text-download">
                {{ $t("download-catalog-text") }}
              </p>
            </v-card-text>
            <v-card-text>
              <div class="name">
                <label>{{ $t("name2") }}</label>
                <input
                  v-model="name"
                  ref="nameTextarea"
                  type="text"
                  :placeholder="textareaPlaceholder"
                  class="name-input"
                />
              </div>

              <div class="name">
                <label
                  >{{ $t("email2") }}<span class="red-dot pl-2">*</span></label
                >
                <input
                  v-model="email"
                  ref="emailTextarea"
                  type="text"
                  placeholder=""
                  class="name-input"
                />
              </div>

              <div class="text-warning d-flex mt-4">
                <label class="checkbox-container">
                  <input
                    type="checkbox"
                    id="consentCheckbox"
                    class="custom-checkbox"
                    v-model="consentChecked"
                  />
                  <span class="checkmark">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                    >
                      <path
                        d="M11.6673 3.5L5.25065 9.91667L2.33398 7"
                        stroke="white"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </span>
                </label>
                <p class="">
                  {{ $t("input-i-agree") }}
                  <router-link class="policy-text-mobile" to="/privacy">{{
                    $t("input-i-agree-2")
                  }}</router-link>
                </p>
              </div>
              <button
                v-on:click="handleSubmitCatalog"
                class="btn-call-modal"
                :disabled="!isValidCatalog"
              >
                {{ $t("catalog-btn") }}
              </button>
            </v-card-text>
          </v-card>
        </v-dialog>
        <v-snackbar v-model="snackbar" :timeout="5000" class="btn-contaiener">
          {{ this.messageSnack }}
          <template v-slot:action="{ attrs }">
            <div
              class="snackbar-btn"
              text
              v-bind="attrs"
              @click="snackbar = false"
            >
              Закрыть
            </div>
          </template>
        </v-snackbar>
      </div>
    </div>
  </div>
</template>


<script>
import Header2 from "@/components/Header2.vue";
import { mask } from "vue-the-mask";
export default {
  name: "Distributor",
  directives: { mask },
  components: {
    Header2,
  },

  data() {
    return {
      showDialog: false,
      showDialogCoopMobile: false,
      downloadDialog: false,
      downloadDialogMobile: false,
      name: "",
      fullname: "",
      email: "",
      phoneNum: "",
      message: "",
      isPlaceholderHidden: false,
      consentChecked: false,
      snackbar: false,
      messageSnack: "",
    };
  },
  watch: {
    // name() {
    //   this.$nextTick(() => {
    //     this.adjustTextareaHeight("nameTextarea");
    //   });
    // },
    // fullname() {
    //   this.$nextTick(() => {
    //     this.adjustTextareaHeight("fullnameTextarea");
    //   });
    // },
    // phoneNum() {
    //   this.$nextTick(() => {
    //     this.adjustTextareaHeight("phoneNumTextarea");
    //   });
    // },
    // email() {
    //   this.$nextTick(() => {
    //     this.adjustTextareaHeight("emailTextarea");
    //   });
    // },
  },
  methods: {
    handleSubmit() {
      //let message = `Телефон: ${this.tel}, Имя: ${this.name}, Комментарий: ${this.description}`;
      let data = {
        EmailToId: "info@rdprooff.ru",
        EmailToName: "rdprooff.ru",
        EmailSubject: "Заявка на сотрудничество",
        EmailBody: `
            <html>
              <body>
                <p style="color:black"><strong>Компания:</strong> ${
                  this.name || "Не указанно"
                }</p>
                <p><strong>Имя:</strong> ${this.fullname}</p>
                <p style="color:black"><strong>Электронная почта:</strong> ${
                  this.email || "Не указано"
                }</p>
                <p><strong>Телефон:</strong> ${this.phoneNum}</p>
                <p style="color:black"><strong> Комментарий:</strong> ${
                  this.message || "Не указано"
                }</p>
                <p style="color:black">С уважением, <br> Команда сайта <a href="rdprooff.ru">rdprooff.ru</a></p>
              </body>
            </html>
            `,
      };

      this.$http
        .post(`api/Email`, data, {
          headers: {
            // Host: 'api.rdprooff.ru',
            "Content-Type": "application/json",
          },
          // body: {
          //   EmailToId: "dimon.cool2000@gmail.com",
          //   EmailToName: "Alex",
          //   EmailSubject: "TestEmail",
          //   EmailBody: `Телефон: ${this.tel}, Имя: ${this.name}, Комментарий: ${this.description}`
          // },
        })
        .then(() => {
          // this.resetModal();
          // this.modal = false;
          // this.$emit("changeModal", this.modal);
          // this.$emit("modalOk");
          this.closeDialog();
          this.closeDialogCoopMobile();
          this.snackbar = true;
          this.messageSnack = "Заявка отправленна";
        })
        .catch((error) => {
          this.error = error;
          this.snackbar = true;
          this.messageSnack = "Ошибка в заявки";
        });
    },
    handleSubmitCatalog() {
      //let message = `Телефон: ${this.tel}, Имя: ${this.name}, Комментарий: ${this.description}`;
      let data = {
        EmailToId: "info@rdprooff.ru",
        EmailToName: "rdprooff.ru",
        EmailSubject: "Заявка на получения каталога",
        EmailBody: `
            <html>
              <body>
                <p style="color:black"><strong>Имя:</strong> ${
                  this.name || "Не указано"
                }</p>
                <p><strong>Электронная почта:</strong> ${this.email}</p>
                <p style="color:black">С уважением, <br> Команда сайта <a href="rdprooff.ru">rdprooff.ru</a></p>
              </body>
            </html>
            `,
      };

      this.$http
        .post(`api/Email`, data, {
          headers: {
            // Host: 'api.rdprooff.ru',
            "Content-Type": "application/json",
          },
          // body: {
          //   EmailToId: "dimon.cool2000@gmail.com",
          //   EmailToName: "Alex",
          //   EmailSubject: "TestEmail",
          //   EmailBody: `Телефон: ${this.tel}, Имя: ${this.name}, Комментарий: ${this.description}`
          // },
        })
        .then(() => {
          // this.resetModal();
          // this.modal = false;
          // this.$emit("changeModal", this.modal);
          // this.$emit("modalOk");
          this.closeDialogDownLoadMobile();
          this.closeDialogDownLoad();
          this.snackbar = true;
          this.messageSnack = "Заявка отправленна";
        })
        .catch((error) => {
          this.error = error;
          this.snackbar = true;
          this.messageSnack = "Ошибка в заявки";
        });
    },
    closeDialogDownLoadMobile() {
      this.downloadDialogMobile = false;
      this.name = "";
      this.email = "";
      this.consentChecked = false;
    },
    openModalDownloadMobile() {
      this.downloadDialogMobile = true;
      this.name = "";
      this.email = "";
      this.consentChecked = false;
    },

    closeDialogDownLoad() {
      this.downloadDialog = false;
      this.name = "";
      this.email = "";
      this.consentChecked = false;
    },
    openModalDownload() {
      this.downloadDialog = true;
      this.name = "";
      this.email = "";
      this.consentChecked = false;
    },

    closeDialogCoopMobile() {
      this.showDialogCoopMobile = false;
      this.name = "";
      this.fullname = "";
      this.email = "";
      this.phoneNum = "";
      this.message = "";
      this.consentChecked = false;
    },
    openModalCoopMobile() {
      this.showDialogCoopMobile = true;
      this.name = "";
      this.fullname = "";
      this.email = "";
      this.phone = "";
      this.message = "";
      this.consentChecked = false;
    },
    closeDialog() {
      this.showDialog = false;
      this.name = "";
      this.fullname = "";
      this.email = "";
      this.phoneNum = "";
      this.message = "";
      this.consentChecked = false;
    },
    openModal() {
      this.showDialog = true;
      this.name = "";
      this.fullname = "";
      this.email = "";
      this.phone = "";
      this.message = "";
      this.consentChecked = false;
    },

    adjustTextareaHeight(refName) {
      const textarea = this.$refs[refName];
      textarea.style.height = "auto";
      textarea.style.height = textarea.scrollHeight + "px";
    },
    restorePlaceholder() {
      this.isPlaceholderHidden = false;
      this.activeTextarea = null;
    },
  },
  computed: {
    textareaPlaceholder() {
      return this.isPlaceholderHidden ? "" : "";
    },
    isValidCatalog() {
      return this.email && this.consentChecked;
    },
    isValidAgree() {
      return this.phoneNum && this.fullname && this.consentChecked;
    },
  },
};
</script>

<style scoped>
.btn-contaiener {
  display: flex;
  justify-content: space-evenly;
}
.snackbar-btn {
  color: var(--03, #bcb648);
  font-family: Inter;
  font-size: 0.875rem;
  font-weight: 400;
  letter-spacing: 0.0178571429em;
  line-height: 1.25rem;
  margin-right: auto;
  padding: 14px 16px;
  text-align: initial;
  cursor: pointer;
}
.btn-call-modal:disabled {
  background-color: grey; /* Серый цвет фона для неактивной кнопки */
  color: white; /* Цвет текста на неактивной кнопке (белый, чтобы контрастировать с серым фоном) */
}
.catalog-text-download {
  color: var(--07, #000);
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.policy-text-mobile {
  color: var(--07, #000);
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration-line: underline;
}

/* .img-container-mobile {
  margin-right: 20px;
} */
.second-block-mobile {
  display: flex;
  justify-content: space-between;
  margin-top: 45px;
  margin-bottom: 58px;
}
.distributor-picture-big-mobile {
  width: 167px;
  height: 218px;
}
.btn-container-mobile {
  display: flex;
  flex-direction: column;
  margin-top: 13px;
}

.btn-mobile-1 {
  margin-bottom: 32px; /* Добавляет отступ между кнопками */
}
.btn-mobile {
  display: flex;
  width: auto;
  padding: 16px 32px;
  justify-content: center;
  align-items: center;
  gap: 32.5px;
  border-radius: 4px;
  background: var(--03, #bcb648);
  color: var(--06, #fff);
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.description-text-bold-mobile {
  color: var(--07, #000);
  font-family: Montserrat;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.description-text-mobile {
  color: var(--07, #000);
  font-family: Montserrat;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.distributor-1-mobile {
  margin-top: 27px;
  margin-bottom: 28px;
}
.name {
  padding-bottom: 14px;
}
.title-name-mobile {
  color: var(--01, #603725);
  font-family: Montserrat;
  font-size: 24px;
  font-style: normal;
  font-weight: 200;
  line-height: normal;
  text-transform: uppercase;
}
.distributor-container-mobile {
  margin-top: 19px;
  padding-right: 16px;
  padding-left: 16px;
}
.name-input:focus {
  border-color: #dae3ea; /* Замените цвет на ваш желаемый цвет */
  outline: none;
}
.name-input {
  width: 100%;
  border-radius: 8px;
  border: 1px solid var(--05, #dae3ea);
  background: var(--06, #fff);
  margin-top: 10px;
  resize: none;
  overflow-y: hidden;
  height: 45px;
  font-size: 16px;
  padding: 10px;
}
.name-input-message {
  width: 100%;
  border-radius: 8px;
  border: 1px solid var(--05, #dae3ea);
  background: var(--06, #fff);
  margin-top: 10px;
  resize: none;
  overflow-y: hidden;
  padding-top: 15px;
  height: 125px;
  font-size: 16px;
  padding-left: 10px;
  padding-right: 10px;
}
.btn-call-modal {
  display: flex;
  padding: 16px 20px;
  justify-content: center;
  align-items: center;
  gap: 32.5px;
  border-radius: 4px;
  background: var(--03, #bcb648);
  color: var(--06, #fff);
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 30px auto;
}
.text-warning {
  color: var(--07, #000);
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  align-items: center;
}
.checkbox-container {
  position: relative;
  width: 22px;
  height: 22px;
  margin-right: 10px;
}

.custom-checkbox {
  opacity: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid black;
  background-color: white; /* Белый фон, когда не выбран */
  border-radius: 3px;
  cursor: pointer;
  transition: background-color 0.3s;
}
.custom-checkbox:checked + .checkmark {
  background-color: var(--03, #bcb648); /* Цвет при выборе */
}

/* .custom-checkbox:checked + .checkmark:after {
  content: "";
  position: absolute;
  left: 8px;
  top: 3px;
  width: 7px;
  height: 12px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
} */

.custom-checkbox:checked:hover + .checkmark {
  background-color: var(--03, #bcb648); /* Цвет при выборе и наведении */
}
.checkbox-container svg {
  width: 80%;
  height: 80%;
  flex-shrink: 0;
  stroke-width: 2px;
  stroke: var(--light-white, #fff);
  align-items: center;
  position: absolute;
  top: 2px;
  left: 2px;
}
.name > label {
  color: var(--07, #000);
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 10px;
  margin-bottom: 10px;
}
::v-deep .v-dialog {
  border-radius: 20px !important;
  background: var(--06, #fff) !important;
}
.application-title {
  color: var(--01, #603725);
  font-family: Montserrat;
  font-size: 24px !important;
  font-style: normal;
  font-weight: 700 !important;
  line-height: normal;
}
.btn-distributor {
  margin-right: 24px;
}
.second-block {
  /* width: 33.3333333333vw; */
  /* width: 640px; */
  max-width: 640px;

  /* margin-right: 32px; */
}

.second-block-mobile {
  display: flex;
  /* width: 640px; */
  overflow: hidden;
}

.first-block {
  /* width: 33.3333333333vw; */
  /* width: 640px; */
  max-width: 640px;

  margin-right: 32px;
}
.btn-container {
  display: flex;
  margin-top: 38px;
}
.second-block {
  display: flex;
  width: 640px;
  /* height: 403px; */
  overflow: hidden;
}
.img-container {
  margin-right: 32px;
}
.distributor-picture-big {
  /* width: 15.8333333333vw;
  height: 36.6666666667vh; */
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.distributor-picture-big-1 {
  /* width: 15.8333333333vw;
  height: 36.6666666667vh; */
  /* margin-right: 1.66666666667vw; */
  /* margin-right: 32px; */
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.btn-distributor,
.btn-download {
  display: inline-flex;
  padding: 16px 32px;
  justify-content: center;
  align-items: center;
  gap: 32.5px;
  border-radius: 4px;
  background: var(--03, #bcb648);
  color: var(--06, #fff);
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.description-text-bold {
  color: var(--07, #000);
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 24px;
}
.description-text {
  color: var(--07, #000);
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.distributor-1 {
  width: 177px;
  height: 84px;
  margin-top: 29px;
  margin-bottom: 28px;
}

.title-name {
  color: var(--01, #603725);
  font-family: Montserrat;
  font-size: 35px;
  font-style: normal;
  font-weight: 200;
  line-height: normal;
  text-transform: uppercase;
}

.distributor-container {
  /* margin-left: 304px;
  margin-right: 304px; */
  display: flex;
  justify-content: center;
  /* margin-top: 4.62962962963vh; */
  margin-top: 50px;
  margin-bottom: 177px;
  width: 100%;
  padding: 12px;
  margin-right: auto;
  margin-left: auto;
}
.red-dot {
  color: #e41313;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

@media (max-width: 1263px) {
  .img-hidden {
    display: none;
  }
  .distributor-container-mobile {
    margin-top: 19px;
    padding-right: 32px;
    padding-left: 32px;
    display: flex;
    margin-bottom: 88px;
    justify-content: space-between;
  }

  .first-block-mobile {
    max-width: 386px;
    margin-right: 66px;
  }

  .img-container-mobile {
    margin-bottom: 31px;
  }

  .btn-mobile {
    display: flex;
    width: fit-content;
    padding: 16px 32px;
    justify-content: center;
    align-items: center;
    gap: 32.5px;
    border-radius: 4px;
    background: var(--03, #bcb648);
    color: var(--06, #fff);
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .second-block-mobile {
    display: flex;
    width: 100%;
    /* height: 403px; */
    overflow: hidden;
    flex-direction: column;
    margin-top: 0px;
    margin-bottom: 0px;
    justify-content: flex-start;
  }
  .distributor-picture-big-mobile {
    width: 370px;
    height: 460px;
    /* -o-object-fit: cover;
    object-fit: cover; */
  }

  .distributor-1-mobile {
    margin-top: 27px;
    margin-bottom: 28px;
    width: 131px;
    height: 62px;
  }
  .description-text-mobile {
    color: var(--07, #000);
    font-family: Montserrat;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: 380px;
  }
  .img-container-2-mobile {
    display: flex;
    justify-content: flex-end;
  }
  .first-block-mobile {
    max-width: 386px;
    margin-right: 0px;
  }
}

@media (max-width: 653px) {
  .img-hidden {
    display: flex;
  }
  .policy-text-mobile {
    color: var(--07, #000);
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-decoration-line: underline;
  }

  .first-block-mobile {
    max-width: 100%;
    margin-right: 0px;
  }

  /* .img-container-mobile {
  margin-right: 20px;
} */
  .second-block-mobile {
    display: flex;
    justify-content: space-between;
    margin-top: 45px;
    margin-bottom: 0px;
    flex-direction: row;
  }
  .img-container-mobile {
    margin-bottom: 0px;
    margin-right: 10px;
  }
  .distributor-picture-big-mobile {
    /* width: 167px;
    height: 218px; */
    width: 100%;
    height: 100%;
  }
  .btn-container-mobile {
    display: flex;
    flex-direction: column;
    margin-top: 38px;
  }

  .btn-mobile-1 {
    margin-bottom: 32px; /* Добавляет отступ между кнопками */
  }
  .btn-mobile {
    display: flex;
    width: auto;
    padding: 16px 32px;
    justify-content: center;
    align-items: center;
    gap: 32.5px;
    border-radius: 4px;
    background: var(--03, #bcb648);
    color: var(--06, #fff);
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .description-text-bold-mobile {
    color: var(--07, #000);
    font-family: Montserrat;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .description-text-mobile {
    color: var(--07, #000);
    font-family: Montserrat;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .distributor-1-mobile {
    margin-top: 27px;
    margin-bottom: 28px;
  }
  .title-name-mobile {
    color: var(--01, #603725);
    font-family: Montserrat;
    font-size: 24px;
    font-style: normal;
    font-weight: 200;
    line-height: normal;
    text-transform: uppercase;
  }
  .distributor-container-mobile {
    margin-top: 19px;
    padding-right: 16px;
    padding-left: 16px;
    display: flex;
    flex-direction: column;
  }
}
@media screen and (max-width: 365px) {
  /* Если ширина экрана больше 768px, кнопки будут располагаться горизонтально */
  .btn-mobile {
    width: auto;
  }

  .distributor-picture-big-mobile {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    margin-bottom: 10px;
  }
  .second-block-mobile {
    display: flex;
    flex-direction: column;
  }

  ::v-deep .v-dialog {
    border-radius: 4px;
    margin: 0px;
    overflow-y: auto;
    pointer-events: auto;
    transition: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
    width: 100%;
    z-index: inherit;
    outline: none;
    box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.2),
      0 24px 38px 3px rgba(0, 0, 0, 0.14), 0 9px 46px 8px rgba(0, 0, 0, 0.12);
  }

  .img-container-mobile {
    margin-bottom: 0px;
    margin-right: 0px;
  }
}

@media screen and (max-width: 700px) {
  /* Если ширина экрана больше 768px, кнопки будут располагаться горизонтально */
  ::v-deep .v-card__title {
    align-items: center;
    display: flex;
    flex-wrap: nowrap;
    font-size: 1.25rem;
    font-weight: 500;
    letter-spacing: 0.0125em;
    line-height: 2rem;
    word-break: inherit;
    align-items: baseline;
  }

  .v-dialog > .v-card > .v-card__subtitle,
  .v-dialog > .v-card > .v-card__text {
    padding: 0 18px 10px;
  }

  .btn-call-modal {
    display: flex;
    padding: 16px 20px;
    justify-content: center;
    align-items: center;
    gap: 32.5px;
    border-radius: 4px;
    background: var(--03, #bcb648);
    color: var(--06, #fff);
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    /* margin: 30px auto; */
    /* text-align: center; */
    width: 100%;
    margin-top: 31px;
    margin-bottom: 15px;
  }

  .v-dialog > .v-card > .v-card__title {
    font-size: 1.25rem;
    font-weight: 500;
    letter-spacing: 0.0125em;
    padding: 16px 24px 10px;
    padding-top: 13px;
    padding-right: 17px;
    padding-bottom: 10px;
    padding-left: 14px;
  }
  .checkbox-container {
    position: relative;
    width: 25px;
    height: 25px;
    margin-right: 15px;
  }
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    width: 22px;
    height: 22px;
    border: 1px solid black;
    background-color: white;
    border-radius: 3px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
}
</style>

